// eslint-disable-next-line import/prefer-default-export
export const pennsylvaniaHero = {
  title: "More than a point-of-sale. A better way to run your business",
  heroImg: "video-penn-bg.png",
  imageClassName: "fixed-height-700 top-adjustement-b",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/local/pennsylvania/demo",
  },
  video: {
    videoBtn: "Play video",
    posterWrapperClass: "",
    posterImg: "hero-video-image.png",
    videoUrlId: "hLlIUkFESiw",
  },
};

export const pennsylvaniaLargeFeatures = {
  featureBlocks: [
    {
      blockTitle: "Contactless payments \n & ordering",
      blockImg: "pos-payment-04.png",
      blockSubTitle:
        "A fully contactless engagement with your customers not only means speed and efficiency, but also peace of mind for the safety of your guests and staff.",
      blockList: [
        "Handheld POS",
        "QR code on receipt",
        "Credit card terminal",
        "Customer-facing displays",
        "Mobile card readers",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/local/pennsylvania/demo",
      },
    },
    {
      blockTitle: "Loyalty rewards for repeat customers",
      blockSubTitle:
        "Boost revenue, grow your customer base, and encourage repeat visits by offering custom loyalty rewards with SpotOn Loyalty. It’s easy to set up and easy to manage.",
      blockImg: "loyalty-penn.png",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
        "Guest and campaign data & insights",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/local/pennsylvania/demo",
      },
    },
  ],
};
export const pennsylvaniaRestaurantTypes = [
  {
    content: "Casual Full-Service",
    desc: "Turn tables faster while creating a top-notch experience that lets guests order and pay their way.",
    img: "casual_fs.png",
  },
  {
    content: "Bars & Nighclubs",
    desc: "Streamline operations behind the bar and on the floor, and monitor bar stations remotely.",
    img: "bars_clubs.png",
  },
  {
    content: "Quick Service",
    desc: "Speed up the checkout process with built-in deal redemptions and loyalty rewards to drive repeat traffic.",
    img: "quick-serve.png",
  },
  {
    content: "Enterprise",
    desc: "Manage menus and labor across multiple location, with real-time customer insights and data.",
    img: "enterprise.png",
  },
  {
    content: "Pizzeria",
    desc: "Simplify operations, including delivery, while giving your customers multiple ways to order and pay.",
    img: "pizzeria.png",
  },
  {
    content: "Fine Dining",
    desc: "Create a VIP-level experience and boost efficiency with integrated reservations, waitlisting, and point-of-sale.",
    img: "fine-dine.png",
  },
];

export const pennsylvaniaTestimonials = {
  title: "What SpotOn customers in Pennsylvania are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const pennsylvaniaIconTemplate = [
  {
    content: "Front-of-house",
    desc: "Create an unforgettable guest experience & streamline operations",
    img: "book-open.png",
  },
  {
    content: "Back-of-house",
    desc: "Update menus, pricing specials, and team members in real-time",
    img: "urgent.png",
  },
  {
    content: "Handheld POS & mobile ordering",
    desc: "Ditch the wires to better serve guests and turn tables faster",
    img: "calc.png",
  },
  {
    content: "Real-time guest data & reporting",
    desc: "Get the insights you need to adapt quickly, including right from your phone",
    img: "pie-chart.png",
  },
  {
    content: "Third-party integrations",
    desc: "Connect your POS to the other software and tools you rely on",
    img: "git-merge.png",
  },
  {
    content: "Face-to-face installation & training",
    desc: "Get your staff started right with help from real-life human experts",
    img: "award.png",
  },
  {
    content: "Online ordering",
    desc: "If you’re looking for quick, easy order and pay options that are a cut above your competitors, SponOn’s point-of-sale solutions can deliver all that plus loyalty programs that work",
    img: "online-purchase.png",
  },
  {
    content: "Reservations",
    desc: "Easily manage your waitlist and reservation system in real time while staying compliant with Pennsylvania’s contract tracing requirements",
    img: "reservation.png",
  },
];

export const pennsylvaniaAutoIconTemplate = [
  {
    content: "SpotOn Terminal",
    desc: "Cut the wires, connect with customers, and get paid. Take payments wherever you want, offer cash discounts, print receipts, and drive repeat visits with built-in digital loyalty",
    img: "virtual-terminal.png",
  },
  {
    content: "Online Appointments",
    desc: "Easily manage appointments and team member schedules while allowing your customers to book at any time, right from your website or your Facebook page",
    img: "appointment.png",
  },
  {
    content: "Custom Web Design",
    desc: "Our specialists will build a professional website for you at a do-it-yourself price, so you show up higher in Google searches and attract more customers",
    img: "website.png",
  },
  {
    content: "Integrated marketing tools",
    desc: "Run and grow your business from a single dashboard—online or right from your phone. Manage your payments, loyalty rewards, and appointments, send marketing emails, monitor your online reputation on Yelp and Google, and send invoices",
    img: "marketing.png",
  },
];

export const pennsylvaniaXL = {
  mainTitle: "Your local Pennsylvania support team",
  subtext:
    "Gain confidence by doing business with professionals you can trust. SpotOn has a team of local experts who care about the growth and success of your business. And 24/7 tech support gives you that extra assurance at no extra cost.",
  phoneNumber: "+18145669337",
  phoneText: "(814) 566-9337",
  email: "danielcabanillas@spoton.com",
};

export const pennsylvaniaWhiteGlove = {
  mainTitle: "We are there for you",
  title: "Service and Support",
  content:
    "In-person installation and training by a Pennsylvania support team backed by around-the-clock technical support.",
  contentList: [
    "Face-to-face hardware installation",
    "In-person staff training",
    "Virtual training whenever you need it",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/local/pennsylvania/demo",
  },
};
