import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  pennsylvaniaHero,
  pennsylvaniaRestaurantTypes,
  pennsylvaniaIconTemplate,
  pennsylvaniaAutoIconTemplate,
  pennsylvaniaLargeFeatures,
  pennsylvaniaWhiteGlove,
  pennsylvaniaXL,
  pennsylvaniaTestimonials,
} from "../../../data/local/pennsylvania-data";
import Hero from "../../../components/Hero/hero";

import ogImage from "../../../images/global_assets/og-image.png";

const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LocalRestaurants = loadable(() =>
  import("../../../components/ColoradoPortedComps/LocalRest")
);
const Restaurants = loadable(() =>
  import("../../../components/ColoradoPortedComps/Rest")
);
const Partners = loadable(() =>
  import("../../../components/PartnerLogos/Partnersv1")
);
const XLArea = loadable(() =>
  import("../../../components/ColoradoPortedComps/XLArea")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const whiteGloveImport = "caregiver.png";

const pennsylvania = () => {
  return (
    <Layout isLanding phoneNumber="+18145669337" phoneText="(814) 566-9337">
      <SEO
        title="SpotOn | Pennsylvania"
        description="SpotOn"
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero
        hideBanner
        sectionData={pennsylvaniaHero}
        circleBg={false}
        aligned
      />
      <Partners />
      <Restaurants data={pennsylvaniaIconTemplate} nav="#" />
      <LocalRestaurants data={pennsylvaniaRestaurantTypes} nav="#" />
      <Restaurants
        title="SpotOn Auto"
        subtext="Increase sales and save time with one integrated system to attract new customers, manage service appointments, improve customer loyalty, and get paid faster."
        data={pennsylvaniaAutoIconTemplate}
        image="auto.png"
        nav="#"
      />
      <TestmonialReviews sectionData={pennsylvaniaTestimonials} />
      <LargeFeatures
        sectionData={pennsylvaniaLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <XLArea data={pennsylvaniaXL} nav="#" />
      <WhiteGlove
        sectionData={pennsylvaniaWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
    </Layout>
  );
};

export default pennsylvania;
